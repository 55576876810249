import Utils from "../../services/Utils";
import { GApi, defineCancelApiObject, defaultHeaders } from "../config/GApi";
import { FormErrorStrategy } from "../config/ErrorHandler";

const AccessStatsApi = {
  get: async function (reportId, cancel = false) {
    const response = await GApi.request({
      url: "/global-reporting/access-stats/" + reportId,
      method: "GET",
      headers: defaultHeaders(),
      errorStrategy: FormErrorStrategy,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    Utils.ensureJson(response);

    return response.data;
  },
};

// defining the cancel API object for ProductAPI
const cancelApiObject = defineCancelApiObject(AccessStatsApi);
export default AccessStatsApi;
