// import the library
import { library } from '@fortawesome/fontawesome-svg-core'

// import your icons
import {
  faArrowRotateRight,
  faBuilding,
  faCalendarDays,
  faChartLine,
  faCircleCheck,
  faCircleUser,
  faCircleXmark,
  faExclamation,
  faFileExcel,
  faFileImport,
  faListCheck,
  faNavicon,
  faPersonChalkboard,
  faRightToBracket,
  faRobot,
  faRotate,
  faTableList,
  faThumbsDown,
  faThumbsUp,
  faTrashCan,
  faTruckFast,
  faUnlock,
  faUsers,
  faUserTie,
  faUserLock,
  faXmark,
} from '@fortawesome/free-solid-svg-icons'

library.add(
  faArrowRotateRight,
  faBuilding,
  faCalendarDays,
  faChartLine,
  faCircleCheck,
  faCircleUser,
  faCircleXmark,
  faExclamation,
  faFileExcel,
  faFileImport,
  faListCheck,
  faNavicon,
  faPersonChalkboard,
  faRightToBracket,
  faRobot,
  faRotate,
  faTableList,
  faThumbsDown,
  faThumbsUp,
  faTrashCan,
  faTruckFast,
  faUnlock,
  faUsers,
  faUserLock,
  faUsers,
  faUserTie,
  faUserLock,
  faXmark
)
