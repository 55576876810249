import Utils from "../../services/Utils";
import {
  GApi,
  defineCancelApiObject,
  defaultHeaders,
  csvDownloadHeaders,
} from "../config/GApi";
import { FormErrorStrategy } from "../config/ErrorHandler";
import Download from "../../services/Download";

const AccessLogApi = {
  getList: async function (finYear, cancel = false) {
    const response = await GApi.request({
      url: "/global-reporting/access-log",
      method: "GET",
      headers: defaultHeaders(),
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    Utils.ensureJson(response);

    return response.data;
  },
  get: async function (reportId, cancel = false) {
    const response = await GApi.request({
      url: "/global-reporting/access-log/" + reportId,
      method: "GET",
      headers: defaultHeaders(),
      errorStrategy: FormErrorStrategy,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    Utils.ensureJson(response);

    return response.data;
  },
  exportAsCsv: async function (cancel = false) {
    const response = await GApi.request({
      url: "/global-reporting/access-log",
      method: "GET",
      headers: csvDownloadHeaders(),
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    Download.csv("report-access-logs.csv", response.data);
  },
  exportReportAsCsv: async function (reportId, cancel = false) {
    const response = await GApi.request({
      url: "/global-reporting/access-log/" + reportId,
      method: "GET",
      headers: csvDownloadHeaders(),
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    Download.csv("report-access-logs.csv", response.data);
  },
};

// defining the cancel API object for ProductAPI
const cancelApiObject = defineCancelApiObject(AccessLogApi);
export default AccessLogApi;
